/* Основные стили для всех разрешений */
body, html, #root, .App {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
}

* {
  box-sizing: border-box;
}

/* Основной блок */
.App-main {
  height: 100vh;
  background: url('/public/logo1.png') no-repeat center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding-left: 10%; /* Это может остаться для десктопов */
}

.App-main::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.09);
  z-index: 1;
}

.overlay {
  position: absolute;
  top: 35%;
  left: 7%;
  transform: translateY(-50%);
  padding: 20px;
  text-align: left;
  color: white;
  z-index: 10;
  max-width: 600px;
}

h1 {
  margin: 0;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.2;
  text-align: left;
  max-width: 500px;
  color: #ffffff;
  z-index: 20;
  position: relative;
}

.learn-more-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: transparent;
  color: white;
  border: 2px solid white;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  z-index: 20;
  position: relative;
}

.learn-more-button:hover {
  background-color: rgba(154, 154, 154, 0.2);
  color: white;
  border-color: white;
}

/* Медиазапросы */
/* Адаптация для 1024px и ниже */
@media (min-width: 1720px) {


    .contact-header .header-image {
        width: 100%;
  height: 60vh;
  max-height: 60vh;
  object-position: 50% 43%; /* 50% по горизонтали, 60% по вертикали, ниже центра */
  display: block;
    }
}
@media (max-width: 768px) {
  .App-main {
    height: 70vh; /* Ограничиваем высоту для мобильных */
    padding-left: 5%;
    justify-content: center;
    background-position: top center; /* Смещаем изображение, чтобы было видно важные части */
    background-size: cover;
  }

  .overlay {
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    max-width: 90%;
    padding: 10px;
  }

  h1 {
    font-size: 1.8rem;
    text-align: center;
    max-width: 100%;
  }

  .learn-more-button {
    width: 100%;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .App-main {
    height: 60vh; /* Еще больше уменьшаем высоту для маленьких экранов */
    padding-left: 0;
    justify-content: center;
    background-position: top center;
  }

  .overlay {
    top: 35%;
    left: auto;
    right: 5%;
    transform: translateY(-50%);
    text-align: left; /* Выравнивание текста по левому краю */
    max-width: 90%;
  }

  h1 {
    font-size: 1.5rem;
    text-align: center;
  }

  .learn-more-button {
    font-size: 12px;
  }
}
@media (max-width: 393px) {
  .App-main {
    height: 60vh; /* Еще больше уменьшаем высоту для маленьких экранов */
    padding-left: 0;
    justify-content: center;
    background-position: top center;
  }

  .overlay {
    top: 35%;
    left: auto;
    right: 5%;
    transform: translateY(-50%);
    text-align: left; /* Выравнивание текста по левому краю */
    max-width: 90%;
  }

  h1 {
    font-size: 1rem;
    text-align: center;
  }

  .learn-more-button {
    font-size: 10px;
  }
}
/* Сервисы */
.services-section {
  background-color: #3d485d;
  background-size: cover;
  padding: 50px 0;
  min-height: 100vh;
  color: #fff;
  position: relative; /* Добавляем для контроля над размещением линии */
}

.services-section::after {
  content: '';
  display: block;
  width: 100%;
  height: 2px; /* Толщина линии */
  background-color: #ffffff00; /* Цвет линии */
  position: absolute;
  bottom: 0;
  left: 0;
}

.services-wrapper {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 40px; /* Увеличение отступов */
}

.services-title {
  font-family: 'Lora', serif;
  font-weight: 500;
  font-size: 2.5rem;
  margin-left: 20px;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding-left: 20px; /* Отступ для контейнеров сервисов */
}

.service-item {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 5px;
  transition: transform 0.2s;
  min-height: 300px;
}

.service-item:hover {
  transform: translateY(-5px);
}

.all-services {
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 250px;
}

.all-services::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(127, 127, 127, 0.575);
  z-index: 1;
}

.all-services-link {
  position: relative;
  z-index: 2;
  display: block;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  color: white;
  text-decoration: none;
  padding: 20px;
  transition: color 0.3s ease-in-out;
}

.all-services-link:hover {
  color: #ddd;
}

/* Медиазапросы для сервисов */
@media (max-width: 1200px) {
  .services-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Адаптация для 1024px и ниже */
@media (min-width: 1720px) {


    .contact-header .header-image {
        width: 100%;
  height: 60vh;
  max-height: 60vh;
  object-position: 50% 43%; /* 50% по горизонтали, 60% по вертикали, ниже центра */
  display: block;
    }
}
@media (max-width: 768px) {
  .services-grid {
    grid-template-columns: 1fr;
    padding-left: 0; /* Убираем отступы для маленьких экранов */
  }

  .services-title {
    text-align: center;
    margin-left: 0;
    
  }
}

@media (max-width: 480px) {
  .services-title {
    font-size: 1.8rem;
  }

  .all-services-link {
    font-size: 1.5rem;
  }
}