/* Основные стили для хедера */
.header {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
}

.header-logo img {
  height: 60px;
  width: auto;
  margin-left: 6rem; /* Небольшой отступ слева для стандартных разрешений */
}

.navigation {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
}

.navigation ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.navigation li {
  margin: 0 1rem;
}

.navigation a {
  color: white;
  text-decoration: none;
  font-size: 1.3rem;
  font-weight: 300;
  transition: color 0.3s ease-in-out, border-bottom 0.3s ease-in-out;
  padding-bottom: 5px;
  border-bottom: 2px solid transparent;
}

.navigation a:hover {
  color: aliceblue;
  border-bottom: 2px solid aliceblue;
}


/* Иконка бургер-меню */
.burger-menu-icon {
  display: none;
  cursor: pointer;
  z-index: 2000;
  position: absolute;
  right: 1.5rem;
  top: 1.3rem;
}

/* Анимация для бокового меню */
.sidebar {
  position: fixed;
  top: 0;
  right: -100%; /* Изначально за экраном */
  width: 100%;
  height: 100%;
  background-color: #000;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 1500;
  overflow-y: auto;
  transition: right 0.5s ease-in-out;
}

.sidebar.open {
  right: 0;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.sidebar li {
  margin: 1rem 0;
}

/* Белый цвет текста в sidebar */
.sidebar a {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: 500;
  transition: color 0.3s ease-in-out, filter 0.3s ease-in-out;
  display: block;
}

.sidebar a:hover {
  color: aliceblue;
  filter: brightness(0.8);
}

/* Лого в sidebar */
.sidebar .header-logo {
  margin-bottom: 1rem; /* Отступ снизу для логотипа */
  margin-left: 0; /* Сдвигаем логотип влево */
  width: 100%; /* Чтобы логотип тянулся по ширине */
  display: flex; /* Для гибкости выравнивания */
  justify-content: flex-start; /* Выравниваем логотип по левому краю */
}

.sidebar .header-logo img {
  max-height: 60px;
  width: auto;
  display: block; /* Обязательно добавьте это, чтобы лого отображалось */
}

/* Показываем иконку бургер-меню только на мобильных устройствах */
@media (max-width: 1280px) {
  .header-image {
    height: 50vh !important; /* Применяем фиксированную высоту с !important */
    max-height: 50vh !important; /* Ограничиваем максимальную высоту */
    object-fit: cover; /* Изображение обрезается по контейнеру */
    width: 100%; /* На всю ширину */
}
  .navigation {
    display: block; /* Скрываем навигацию для мобильных */
  }

  .burger-menu-icon {
    display: block;
  }

  /* Лого сдвигается максимально влево */
  .header-logo img {
    margin-left: 0; /* Сбрасываем отступ для мобильных */
    max-height: 50px;
  }
  .navigation a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 600;
    transition: color 0.3s ease-in-out, border-bottom 0.3s ease-in-out;
    padding-bottom: 5px;
    border-bottom: 2px solid transparent;
  }
}
@media (max-width: 1024px) {
  .header-image {
    height: 25vh !important; /* Применяем фиксированную высоту с !important */
    max-height: 25vh !important; /* Ограничиваем максимальную высоту */
    object-fit: cover; /* Изображение обрезается по контейнеру */
    width: 100%; /* На всю ширину */
  }
  .navigation {
    display: block; /* Скрываем навигацию для мобильных */
  }

  .burger-menu-icon {
    display: block;
  }

  /* Лого сдвигается максимально влево */
  .header-logo img {
    margin-left: 0; /* Сбрасываем отступ для мобильных */
    max-height: 50px;
  }
  .navigation a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 600;
    transition: color 0.3s ease-in-out, border-bottom 0.3s ease-in-out;
    padding-bottom: 5px;
    border-bottom: 2px solid transparent;
  }
}

/* Для экранов меньше 768px */
/* Адаптация для 1024px и ниже */
@media (min-width: 1720px) {


    .contact-header .header-image {
        width: 100%;
  height: 60vh;
  max-height: 60vh;
  object-position: 50% 43%; /* 50% по горизонтали, 60% по вертикали, ниже центра */
  display: block;
    }
}
@media (max-width: 768px) {
  .header-logo img {
    max-height: 50px; /* Уменьшаем размер логотипа */
    margin-left: 0; /* Сохраняем лого максимально слева */
  }

  .sidebar .header-logo img {
    max-height: 60px;
    width: auto;
    display: block; /* Обязательно добавьте это, чтобы лого отображалось */
  }
  .header::after {
    display: none;
  }
}

/* Для экранов меньше 480px */
@media (max-width: 480px) {
  .header-logo img {
    max-height: 3rem;
    margin-left: 0; /* Лого влево */
  }
  .header::after {
    display: none;
  }
}

@media (max-width: 430px) {
  .header-logo img {
    max-height: 2.5rem;
    margin-left: 0;
  }
  .header::after {
    display: none;
  }
}

@media (max-width: 393px) {
  .header-logo img {
    max-height: 2rem;
    margin-left: 0;
  }
  .header::after {
    display: none;
  }
}