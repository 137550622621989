.document-category-page {
  padding-top: 0;
  min-height: 100vh; /* Страница занимает весь экран */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Контент равномерно распределен */
}

.document-category-card {
  width: 50%; /* Уменьшена ширина для всех разрешений */
  max-width: 800px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin: 100px auto 70px auto; /* Центрируем и добавляем отступы */
}

.document-card-header {
  background-color: #4c8bf5;
  padding: 20px;
  border-radius: 10px 10px 0 0;
}

.document-card-header h3 {
  color: white;
  font-size: 1.5rem;
  margin: 0;
}

.document-card-body {
  padding: 20px;
}

.document-card-body ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.document-card-body ul li {
  margin-bottom: 10px;
  font-size: 1rem;
  color: #333333;
}

.document-card-body ul li a {
  color: #007bff;
  text-decoration: none;
}

.document-card-body ul li a:hover {
  text-decoration: underline;
}

.document-card-body ul li::before {
  content: "•";
  color: #007bff;
  font-weight: bold;
  display: inline-block; 
  width: 1em;
  margin-left: -1em;
}

/* Медиазапросы для адаптации */

/* Адаптация для 1024px и ниже */
@media (max-width: 1024px) {
  .header-image {
    height: 25vh !important; /* Применяем фиксированную высоту с !important */
    max-height: 25vh !important; /* Ограничиваем максимальную высоту */
    object-fit: cover; /* Изображение обрезается по контейнеру */
    width: 100%; /* На всю ширину */
  }
  .documents-header h1 {
  font-size: 2.5rem; /* Уменьшение шрифта */
  top: 13%;
  left: 14%;
  transform: translateX(-50%);
}

.document-category-card {
  width: 70%; /* Увеличение ширины карточки */
}

.document-card-header h3 {
  font-size: 1.3rem;
}
}

/* Адаптация для 820px и ниже */
@media (max-width: 820px) {
.documents-header h1 {
  font-size: 1.8rem; /* Уменьшение шрифта */
  top: 13vh;
  left: 16%;
  transform: translateX(-50%);
}

.document-category-card {
  width: 80%; /* Увеличение ширины карточки */
}

.document-card-header h3 {
  font-size: 1.2rem;
}

.document-card-body ul li {
  font-size: 0.9rem;
}
}

/* Адаптация для 768px и ниже */
/* Адаптация для 1024px и ниже */
@media (min-width: 1720px) {


    .contact-header .header-image {
        width: 100%;
  height: 60vh;
  max-height: 60vh;
  object-position: 50% 43%; /* 50% по горизонтали, 60% по вертикали, ниже центра */
  display: block;
    }
}
@media (max-width: 768px) {
.documents-header h1 {
  font-size: 1.8rem; 
  top: 13vh;
  left: 20%;
  transform: translateX(-50%);
}

.document-category-card {
  width: 90%;
  margin-left: 0;
}

.document-card-header h3 {
  font-size: 1.1rem;
}

.document-card-body {
  padding: 15px;
}

.document-card-body ul li {
  font-size: 0.8rem;
}
}

/* Адаптация для 480px и ниже */
@media (max-width: 480px) {
.documents-header h1 {
  font-size: 1.2rem; 
  top: 8vh;
  left: 21%;
  transform: translateX(-50%);
}

.document-category-card {
  width: 100%;
  margin-left: 0;
}

.document-card-header h3 {
  font-size: 1rem;
}

.document-card-body {
  padding: 10px;
}

.document-card-body ul li {
  font-size: 0.75rem;
}
}

/* Адаптация для 393px и ниже */
@media (max-width: 393px) {
.documents-header h1 {
  font-size: 0.9rem; /* Smaller font size */
    top: 10vh; /* Adjust positioning */
    left: 15%; /* Center horizontally */
    transform: translateX(-50%);
}

.document-category-card {
  width: 100%;
  margin-left: 0;
}

.document-card-header h3 {
  font-size: 0.9rem;
}

.document-card-body {
  padding: 8px;
}

.document-card-body ul li {
  font-size: 0.7rem;
}
}

/* Адаптация для 375px и ниже */
@media (max-width: 375px) {
.documents-header h1 {
  font-size: 0.9rem; /* Smaller font size */
    top: 12vh; /* Adjust positioning */
    left: 15%; /* Center horizontally */
    transform: translateX(-50%);
}

.document-category-card {
  width: 100%;
  margin-left: 0;
}

.document-card-header h3 {
  font-size: 0.9rem;
}

.document-card-body {
  padding: 8px;
}

.document-card-body ul li {
  font-size: 0.65rem;
}
}