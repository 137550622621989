/* Default Styles */
.vacancy-details-page {
  padding: 0px;
}

.vacancy-details-page h1 {
  font-size: 2rem;
  color: #000000;
  margin-bottom: 20px;
  font-weight: 600;
  margin-top: 100px;
}

.vacancy-content {
  margin-top: 100px;
  width: 70%;
  margin-bottom: 70px;
  margin-left: 148px;
}

.vacancy-page-header-container h1 {
  position: absolute;
  top: 23%;
  left: 17%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 40px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  line-height: 28px;
  z-index: 2;
}

.vacancy-details-page h2 {
  font-size: 1.5rem;
  color: #000000;
  margin-top: 30px;
  margin-bottom: 15px;
  font-weight: 600;
}

.vacancy-details-page p {
  font-size: 1rem;
  line-height: 1.6;
  color: #333333;
  margin-bottom: 20px;
}

.vacancy-details-page .vacancy-content {
  margin-bottom: 20px;
}

.vacancy-details-page .vacancy-apply {
  text-align: center;
  margin-top: 30px;
}

.vacancy-details-page .vacancy-apply button {
  padding: 10px 20px;
  font-size: 1.2rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 50px;
}

.vacancy-details-page .vacancy-apply button:hover {
  background-color: #0056b3;
}

.vacancy-details-page ul {
  margin-left: 20px;
  list-style-type: disc;
}

.vacancy-details-page ul li {
  margin-bottom: 10px;
  font-size: 1rem;
  line-height: 1.6;
  color: #333333;
}

/* Adaptation for 1024px and below */
@media (max-width: 1024px) {
  .header-image {
    height: 25vh !important; /* Применяем фиксированную высоту с !important */
    max-height: 25vh !important; /* Ограничиваем максимальную высоту */
    object-fit: cover; /* Изображение обрезается по контейнеру */
    width: 100%; /* На всю ширину */
  }
  .vacancy-details-page h1, .vacancy-page-header-container h1 {
      font-size: 1.8rem;
  }
  .vacancy-content {
      width: 85%;
      margin-left: 7.5%;
  }
  .vacancy-details-page h2, .vacancy-details-page p {
      font-size: 1.2rem;
  }
  .vacancy-details-page ul {
      margin-left: 10px;
  }
}

/* Adaptation for 820px and below */
@media (max-width: 820px) {
  .vacancy-details-page h1, .vacancy-page-header-container h1 {
      font-size: 1.6rem;
  }
  .vacancy-content {
      width: 90%;
      margin-left: 5%;
  }
  .vacancy-details-page h2, .vacancy-details-page p {
      font-size: 1.1rem;
  }
}

/* Adaptation for 768px and below */
/* Адаптация для 1024px и ниже */
@media (min-width: 1720px) {


    .contact-header .header-image {
        width: 100%;
  height: 60vh;
  max-height: 60vh;
  object-position: 50% 43%; /* 50% по горизонтали, 60% по вертикали, ниже центра */
  display: block;
    }
}
@media (max-width: 768px) {
  .vacancy-details-page h1, .vacancy-page-header-container h1 {
      font-size: 1.5rem;
  }
  .vacancy-content {
      width: 95%;
      margin-left: 2.5%;
  }
  .vacancy-details-page h2, .vacancy-details-page p {
      font-size: 1rem;
  }
}

/* Adaptation for 430px and below */
@media (max-width: 430px) {
  .vacancy-details-page h1, .vacancy-page-header-container h1 {
      font-size: 1.2rem;
  }
  .vacancy-content {
      width: 98%;
      margin-left: 1%;
  }
  .vacancy-details-page h2, .vacancy-details-page p {
      font-size: 0.9rem;
  }
}

/* Adaptation for 393px and below */
@media (max-width: 393px) {
  .vacancy-details-page h1, .vacancy-page-header-container h1 {
    font-size: 0.9rem; /* Smaller font size */
    top: 10vh; /* Adjust positioning */
    left: 15%; /* Center horizontally */
    transform: translateX(-50%);
  }
  .vacancy-content {
      width: 100%;
      margin-left: 0;
  }
  .vacancy-details-page h2, .vacancy-details-page p {
      font-size: 0.8rem;
  }
}

/* Adaptation for 375px and below */
@media (max-width: 375px) {
  .vacancy-details-page h1, .vacancy-page-header-container h1 {
    font-size: 0.9rem; /* Smaller font size */
    top: 12vh; /* Adjust positioning */
    left: 15%; /* Center horizontally */
    transform: translateX(-50%);
  }
  .vacancy-content {
      width: 100%;
      margin-left: 0;
  }
  .vacancy-details-page h2, .vacancy-details-page p {
      font-size: 0.8rem;
  }
}