.documents-page {
  padding-top: 0;
}

.documents-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

/* Карточки документов */
.document-card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  margin-left: 0px;
  margin-bottom: 70px;
}

.documents-header h1 {
  position: absolute;
  top: 33%;
  left: 15%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 3vw;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  line-height: 1.2;
  z-index: 2;
}

.document-card-header {
  background-color: #007bff;
  border-radius: 10px 10px 0 0;
  padding: 15px;
  color: white;
}

.document-card-header h3 {
  margin: 0;
  font-size: 1.2rem;
}

.document-card-body ul {
  list-style-type: disc;
  padding-left: 20px;
  margin: 10px 0;
}

.document-card-body ul li {
  margin-bottom: 10px;
}

.document-card-footer {
  text-align: right;
  margin-top: auto;
}

.document-detail-link {
  color: #007bff;
  text-decoration: none;
  font-size: 0.9rem;
}

.document-detail-link:hover {
  text-decoration: underline;
}

/* Адаптация для 1024px и ниже */
@media (max-width: 1024px) {
  .header-image {
    height: 25vh !important; /* Применяем фиксированную высоту с !important */
    max-height: 25vh !important; /* Ограничиваем максимальную высоту */
    object-fit: cover; /* Изображение обрезается по контейнеру */
    width: 100%; /* На всю ширину */
  }
  .documents-header h1 {
    font-size: 2.5rem; /* Уменьшаем размер шрифта */
    top:13%;
    left: 14%;
    transform: translateX(-50%);
  }

  .documents-grid {
    grid-template-columns: 1fr 1fr; /* Два столбца вместо трех */
  }

  .document-card {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

/* Адаптация для 820px и ниже */
@media (max-width: 820px) {
  .documents-header h1 {
    font-size: 1.8rem; /* Уменьшаем размер шрифта */
    top: 13vh;
    left: 16%;
    transform: translateX(-50%);
  }

  .documents-grid {
    grid-template-columns: 1fr; /* Один столбец */
  }
}

/* Адаптация для 768px и ниже */
/* Адаптация для 1024px и ниже */
@media (min-width: 1720px) {


    .contact-header .header-image {
        width: 100%;
  height: 60vh;
  max-height: 60vh;
  object-position: 50% 43% !important; /* 50% по горизонтали, 60% по вертикали, ниже центра */
  display: block;
    }
}
@media (max-width: 768px) {
  .documents-header h1 {
    font-size: 1.8rem; /* Уменьшаем размер шрифта */
    top: 13vh;
    left: 20%;
    transform: translateX(-50%);
  }

  .document-card {
    padding: 15px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

/* Адаптация для 480px и ниже */
@media (max-width: 480px) {
  .documents-header h1 {
    font-size: 1.2rem; /* Еще меньше размер шрифта */
    top: 8vh;
    left: 21%;
    transform: translateX(-50%);
  }

  .document-card {
    padding: 10px;
  }

  .document-card-header h3 {
    font-size: 1rem;
  }
}

/* Адаптация для 393px и ниже */
@media (max-width: 393px) {
  .documents-header h1 {
    font-size: 0.9rem; /* Smaller font size */
    top: 10vh; /* Adjust positioning */
    left: 15%; /* Center horizontally */
    transform: translateX(-50%);
  }

  .document-card {
    padding: 10px;
  }

  .document-card-header h3 {
    font-size: 0.9rem;
  }
}

/* Адаптация для 375px и ниже */
@media (max-width: 375px) {
  .documents-header h1 {
    font-size: 0.9rem; /* Smaller font size */
    top: 12vh; /* Adjust positioning */
    left: 15%; /* Center horizontally */
    transform: translateX(-50%);
  }

  .document-card {
    padding: 10px;
  }

  .document-card-header h3 {
    font-size: 0.9rem;
  }
}