/* Основные стили для страницы */
.development-detail-page {
  padding: 0px;
}

.development-header {
  text-align: center;
  position: relative;
}

.development-header h1 {
  position: absolute;
  top: 50%; /* Опускаем заголовок ниже */
  left: 15%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 3vw;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  line-height: 1.2;
  z-index: 2;
  text-align: center;
}

.development-detail-content {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  text-align: left; /* Выравниваем текст по левому краю */
  margin-top: 0px;
}

/* Ограничение изображения */
.development-detail-image {
  width: 100%; /* Изображение занимает всю ширину контейнера */
  height: auto; /* Высота подстраивается автоматически, сохраняя пропорции */
  border-radius: 8px;
  object-fit: cover; /* Изображение обрезается по размеру контейнера */
  margin-top: 20px;
}

.development-detail-content h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
  text-align: center; /* Центрируем заголовок */
}

.development-detail-content p {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #555;
  margin-bottom: 20px;
  text-align: left;
}

/* Адаптация для 1024px и ниже */
@media (max-width: 1024px) {
  .header-image {
    height: 25vh !important; /* Применяем фиксированную высоту с !important */
    max-height: 25vh !important; /* Ограничиваем максимальную высоту */
    object-fit: cover; /* Изображение обрезается по контейнеру */
    width: 100%; /* На всю ширину */
  }
  .development-header h1 {
  font-size: 2.5rem;
  top: 50%;
  left: 18%;
  transform: translateX(-50%);
}
.development-detail-content {
  padding: 15px;
  max-width: 90%;
}
}

/* Адаптация для 820px и ниже */
@media (max-width: 820px) {
.development-header h1 {
  font-size: 2rem;
  top: 50%;
  left: 18%;
  transform: translateX(-50%);
}
.development-detail-content {
  padding: 15px;
  max-width: 95%;
}
}

/* Адаптация для 768px и ниже */
/* Адаптация для 1024px и ниже */
@media (min-width: 1720px) {


    .contact-header .header-image {
        width: 100%;
  height: 60vh;
  max-height: 60vh;
  object-position: 50% 41%; /* 50% по горизонтали, 60% по вертикали, ниже центра */
  display: block;
    }
}
@media (max-width: 768px) {
.development-header h1 { 
  font-size: 1.8rem;
  top: 13vh;
  left: 20%;
  transform: translateX(-50%);
}
.development-detail-content {
  padding: 10px;
  max-width: 100%;
}
}

/* Адаптация для 480px и ниже */
@media (max-width: 480px) {
.development-header h1 {
  font-size: 1.2rem;
  top: 8vh;
  left: 21%;
  transform: translateX(-50%);
}
.development-detail-content {
  padding: 10px;
  max-width: 100%;
}
.development-detail-image {
  height: 200px; /* Ограничиваем высоту изображения */
}
}

/* Адаптация для 393px и ниже */
@media (max-width: 393px) {
.development-header h1 {
  font-size: 0.9rem;
  top: 10vh;
  left: 18%;
  transform: translateX(-50%);
}
.development-detail-content {
  padding: 5px;
  max-width: 100%;
}
.development-detail-image {
  height: 180px; /* Ограничиваем высоту изображения */
}
}

/* Адаптация для 375px и ниже */
@media (max-width: 375px) {
.development-header h1 {
  font-size: 0.9rem;
  top: 12vh;
  left: 18%;
  transform: translateX(-50%);
}
.development-detail-content {
  padding: 5px;
  max-width: 100%;
}
.development-detail-image {
  height: 150px; /* Ограничиваем высоту изображения */
}
}