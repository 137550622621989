
.contact-page {
    padding-top: 0;
}
/* Убедимся, что контейнеры занимают 100% ширины */
.contact-container, .contact-info, .contact-form, .contact-map {
    max-width: 100%;
}

.contact-header {
    text-align: center;
    margin-bottom: 40px;
    position: relative;
}

.header-image {
    width: 100%;
    height: auto;
    display: block;
}

.contact-header h1 {
    position: absolute;
    top: 42%;
    left: 17%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 42px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    line-height: 28px;
    z-index: 2;
}

.breadcrumb {
    text-align: left;
    margin-top: 0rem;
}

.breadcrumb-link {
    text-decoration: none;
    color: #007bff;
    font-size: 18px;
}

.breadcrumb-link:hover {
    text-decoration: underline;
}

.contact-container {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    position: relative;
}

.contact-info {
    flex: 1;
    margin-left: 150px;
    margin-top: 30px;
}

.contact-info a {
    color: #333;
    text-decoration: none;
}

.contact-info a:hover {
    text-decoration: underline;
}

.contact-info h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

.contact-info p {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 10px;
    max-width: 200px;
}

.contact-form {
    flex: 2;
    max-width: 300px;
    position: relative;
    left: -200px;
    margin-top: 28px;
}

.contact-form h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

.contact-form label {
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.contact-form textarea {
    height: 100px;
}

.consent {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: nowrap; /* Убираем перенос строк */
}

.consent label {
    margin-left: 0px; /* Уменьшаем отступ до 5px */
    font-size: 14px;
    white-space: nowrap; /* Запрещаем перенос текста */
}

.consent a {
    color: #007bff;
    text-decoration: none;
}

.consent a:hover {
    text-decoration: underline;
}

button[type="submit"] {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 50px;
}

button[type="submit"]:hover {
    background-color: #0056b3;
}

/* Стили для карты */
.contact-map {
    position: absolute;
    top: 125%; /* Настраивайте значение для нужного положения */
    left: 10.5%; /* Оставляем сдвиг влево */
    transform: translateY(-50%); /* Центрируем карту по вертикали */
    width: 100%;
    max-width: 500px;
    height: 300px;
    border: none;
    border-radius: 8px;
    text-align: left;
}

.contact-map h3 {
    font-size: 20px;
    margin-bottom: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
}

.contact-map iframe {
    width: 100%;
    max-width: 500px;
    height: 300px;
    border: none;
    border-radius: 8px;
}

/* Адаптация под мобильные устройства */
/* Адаптация для 1024px и ниже */
@media (min-width: 1720px) {


    .contact-header .header-image {
        width: 100%;
  height: 60vh;
  max-height: 60vh;
  object-position: 50% 43%; /* 50% по горизонтали, 60% по вертикали, ниже центра */
  display: block;
    }
}
@media (max-width: 768px) {
    .contact-map {
        position: relative;
        margin-top: 0px;
        margin-bottom: 10px;
        margin-left: 13%; /* Сдвигаем карту левее */
        transform: translateY(-10%); /* Поднимаем карту выше */
    }

    .contact-map iframe {
        height: 200px;
        width: 90%;
    }
    .contact-container {
        flex-direction: column; /* Располагаем элементы вертикально */
        align-items: center; /* Центрируем элементы */
        text-align: center; /* Центрируем текст */
        gap: 20px; /* Добавляем расстояние между блоками */
    }

    .contact-info {
        margin: 0 auto;
        max-width: 100%; /* Чтобы контактная информация занимала всю ширину */
        text-align: center; /* Центрируем контактную информацию */
    }

    .contact-info h2 {
        font-size: 1.8rem; /* Уменьшаем размер заголовка */
    }

    .contact-info p {
        font-size: 1.2rem; /* Уменьшаем текст */
    }

    .contact-form {
        max-width: 90%; /* Ширина формы на 90% экрана */
        margin: 0 auto; /* Центрируем форму */
        text-align: left; /* Внутри формы текст остаётся по левому краю */
    }

}

@media (max-width: 480px) {
    .contact-map {
        margin-top: 20px;
        text-align: center;
    }

    .contact-map iframe {
        height: 250px;
        width: 90%;
        margin-left: 5%;
    }
}

/* Адаптация для 1024px и ниже */
@media (min-width: 1720px) {


    .contact-header .header-image {
        width: 100%;
  height: 60vh;
  max-height: 60vh;
  object-position: 50% 43%; /* 50% по горизонтали, 60% по вертикали, ниже центра */
  display: block;
    }
}
@media (max-width: 1280px) {
    .contact-map {
        position: absolute;
        top: 125%; /* Настраивайте значение для нужного положения */
        left: 10.5%; /* Оставляем сдвиг влево */
        transform: translateY(-50%); /* Центрируем карту по вертикали */
        width: 100%;
        max-width: 420px;
        height: 300px;
        border: none;
        border-radius: 8px;
        text-align: left;
    }

    .contact-map iframe {
        height: 250px;
        width: 90%;
    }
    .header-image {
        height: 50vh !important; /* Применяем фиксированную высоту с !important */
        max-height: 50vh !important; /* Ограничиваем максимальную высоту */
        object-fit: cover; /* Изображение обрезается по контейнеру */
        width: 100%; /* На всю ширину */
    }
}


/* Адаптация для 820px и ниже */
@media (max-width: 820px) {
    .contact-map {
        position: relative;
        margin-top: 0px;
        margin-bottom: 10px;
        margin-left: 13%; /* Сдвигаем карту левее */
        transform: translateY(-10%); /* Поднимаем карту выше */
    }

    .contact-map iframe {
        height: 200px;
        width: 90%;
    }
    .contact-container {
        flex-direction: column; /* Располагаем элементы вертикально */
        align-items: center; /* Центрируем элементы */
        text-align: center; /* Центрируем текст */
        gap: 20px; /* Добавляем расстояние между блоками */
    }

    .contact-info {
        margin: 0 auto;
        max-width: 100%; /* Чтобы контактная информация занимала всю ширину */
        text-align: center; /* Центрируем контактную информацию */
    }

    .contact-info h2 {
        font-size: 1.8rem; /* Уменьшаем размер заголовка */
    }

    .contact-info p {
        font-size: 1.2rem; /* Уменьшаем текст */
    }

    .contact-form {
        max-width: 90%; /* Ширина формы на 90% экрана */
        margin: 0 auto; /* Центрируем форму */
        text-align: left; /* Внутри формы текст остаётся по левому краю */
    }

}

/* Адаптация для 480px и ниже */
@media (max-width: 430px) {
  .contact-header h1 {
    font-size: 1.2rem;
    top: 8vh;
    left: 21%;
  }

  .contact-info {
    text-align: center;
    margin-left: 0;
  }

  .contact-form {
    left: 0;
  }

  .contact-map {
    position: relative;
    margin-top: 0px; /* Уменьшаем отступ сверху */
    margin-bottom: 10px;
    margin-left: -9%; /* Сдвигаем карту еще левее */
    transform: translateY(-10%); /* Поднимаем карту выше */
  }

  .contact-map iframe {
    height: 250px;
    width: 90%;
    margin-left: 5%;
  }
}

/* Адаптация для 393px и ниже */
@media (max-width: 393px) {
  .contact-header h1 {
    font-size: 0.9rem; /* Smaller font size */
    top: 10vh; /* Adjust positioning */
    left: 15%; /* Center horizontally */
    transform: translateX(-50%);
  }

  .contact-info {
    text-align: center; /* Сдвигаем контакты правее */
    margin-left: auto; /* Убираем отступ слева */
    margin-right: auto; /* Добавляем отступ справа */
}

.contact-form {
    max-width: 100px;
    margin-left: 5%; /* Центрируем форму */
    padding: 0;
}

.contact-map iframe {
    height: 200px;
    width: 90%; /* Используем 90% ширины экрана */
    margin: 0 auto; /* Центрируем карту */
}

.contact-map iframe {
    height: 200px;
    width: 300px;
    margin-left: 0; /* Убираем лишний отступ */
}

.contact-map {
    position: relative;
    margin-top: 0px; /* Убираем отступ сверху */
    margin-bottom: 10px;
    margin-left: -5%; /* Сдвигаем карту левее */
    transform: translateY(-10%); /* Поднимаем карту */
    max-width: 200px;
}
.consent label {
    margin-left: 1px; /* Уменьшаем отступ до 5px */
    white-space: initial; /* Запрещаем перенос текста */
}
.contact-form {
    flex: 2;
    max-width: 400px;
    position: relative;
    margin-top: 28px;
}
}


/* Адаптация для 375px и ниже */
@media (max-width: 375px) {
    .contact-header h1 {
        font-size: 0.9rem; /* Smaller font size */
    top: 12vh; /* Adjust positioning */
    left: 15%; /* Center horizontally */
    transform: translateX(-50%);
    }

    .contact-info {
        text-align: center; /* Сдвигаем контакты правее */
        margin-left: auto; /* Убираем отступ слева */
        margin-right: auto; /* Добавляем отступ справа */
    }

    .contact-form {
        max-width: 100px;
        margin-left: 5%; /* Центрируем форму */
        padding: 0;
    }

    .contact-map iframe {
        height: 200px;
        width: 90%; /* Используем 90% ширины экрана */
        margin: 0 auto; /* Центрируем карту */
    }

    .contact-map iframe {
        height: 200px;
        width: 300px;
        margin-left: 0; /* Убираем лишний отступ */
    }

    .contact-map {
        position: relative;
        margin-top: 0px; /* Убираем отступ сверху */
        margin-bottom: 10px;
        margin-left: -5%; /* Сдвигаем карту левее */
        transform: translateY(-10%); /* Поднимаем карту */
        max-width: 200px;
    }
    .consent label {
        margin-left: 1px; /* Уменьшаем отступ до 5px */
        white-space: initial; /* Запрещаем перенос текста */
    }
    .contact-form {
        flex: 2;
        max-width: 400px;
        position: relative;
        margin-top: 28px;
    }
}
