.news-page-container {
  padding-top: 0;
}

.news-header-container {
  text-align: center;
}

.news-header-container h1 {
  position: absolute;
  top: 30vh; /* Процент от высоты окна для расположения */
  left: 15%; /* Центрирование по горизонтали */
  transform: translateX(-50%);
  color: white;
  font-size: 3rem; /* Использование rem вместо vw для контроля размера шрифта */
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  line-height: 1.2; /* Указан множитель для лучшего контроля */
  z-index: 2;
}

.news-content-container {
  display: flex;
  flex-direction: column;
  gap: 2rem; /* Использование rem для отступов */
  max-width: 800px; /* Ограничение ширины контейнера */
  margin: 0 auto;
  padding: 0 2rem; /* Использование rem для отступов */
}

.news-item-container {
  background-color: #D9D9D9;
  padding: 1.5rem; /* Использование rem для отступов */
  border-radius: 10px; /* Закругленные углы */
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1); /* Использование rem для тени */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  min-height: 220px; /* Фиксированная минимальная высота */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-top: 0rem;
}

.news-item-container:hover {
  transform: translateY(-0.5rem); /* Подъем при наведении */
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15); /* Более выраженная тень */
}

.news-item-container p {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem; /* Использование rem для размера текста */
  line-height: 1.6; /* Оптимизированное межстрочное расстояние */
  margin-top: 0.5rem; /* Небольшой отступ сверху */
  color: #333;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal;
}

.news-date-container {
  font-family: 'Inter', sans-serif;
  font-size: 0.875rem; /* Меньший шрифт */
  color: #999;
  margin-bottom: 1rem; /* Небольшой отступ снизу */
}

.news-title-container {
  font-size: 1.25rem; /* Оптимальный размер заголовка */
  color: #007bff;
  text-decoration: none;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 1rem; /* Небольшой отступ снизу */
  transition: color 0.3s ease;
}

.news-title-container:hover {
  text-decoration: underline;
  color: #0056b3;
}

.news-button-container {
  align-self: flex-end;
  padding: 0.5rem 1rem; /* Небольшие отступы для кнопок */
  font-size: 1rem; /* Унифицированный размер текста */
  background-color: transparent;
  color: #000000;
  border: 2px solid #000000;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 1rem; /* Отступ сверху для кнопки */
}

.news-button-container:hover {
  background-color: rgba(0, 0, 0, 0.25);
  color: #000000;
}

.pagination-container {
  text-align: center;
  margin-top: 4rem; /* Использование rem для отступов */
  margin-bottom: 8rem; /* Использование rem для отступов */
}

.page-number,
.page-arrow {
  display: inline-block;
  margin: 0 1rem; /* Использование rem для отступов */
  padding: 0.75rem 1.25rem; /* Использование rem для отступов */
  font-size: 1rem; /* Унифицированный размер текста */
  color: #007bff;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
}

.page-number:hover,
.page-arrow:hover {
  color: #0056b3;
  background-color: #f0f0f0;
}
@media (max-width: 1280px) {
  .header-image {
    height: 50vh !important; /* Применяем фиксированную высоту с !important */
    max-height: 50vh !important; /* Ограничиваем максимальную высоту */
    object-fit: cover; /* Изображение обрезается по контейнеру */
    width: 100%; /* На всю ширину */
}
  .news-header-container h1 {
    font-size: 2.5rem; /* Reduce font size */
    top: 28%; /* Adjust positioning */
    left: 15%; /* Center horizontally */
    transform: translateX(-50%);
  }

  .news-content-container {
    max-width: 700px; /* Reduce max-width */
    padding: 0 1.5rem; /* Adjust padding */
    gap: 1rem; /* Reduce gap between items */
  }

  .news-item-container {
    padding: 1.2rem; /* Adjust padding */
  }
}
/* Media queries for responsive design */
@media (max-width: 1024px) {
  .header-image {
    height: 25vh !important; /* Применяем фиксированную высоту с !important */
    max-height: 25vh !important; /* Ограничиваем максимальную высоту */
    object-fit: cover; /* Изображение обрезается по контейнеру */
    width: 100%; /* На всю ширину */
  }
  .news-header-container h1 {
    font-size: 2.5rem; /* Reduce font size */
    top: 13%; /* Adjust positioning */
    left: 15%; /* Center horizontally */
    transform: translateX(-50%);
  }

  .news-content-container {
    max-width: 700px; /* Reduce max-width */
    padding: 0 1.5rem; /* Adjust padding */
    gap: 1rem; /* Reduce gap between items */
  }

  .news-item-container {
    padding: 1.2rem; /* Adjust padding */
  }
}

@media (max-width: 820px) {
  .news-header-container h1 {
    font-size: 1.8rem; /* Reduce font size */
    top: 13vh; /* Adjust positioning */
    left: 15%; /* Center horizontally */
    transform: translateX(-50%);
  }

  .news-content-container {
    max-width: 600px; /* Further reduce max-width */
    padding: 0; /* Remove padding */
    gap: 1rem; /* Reduce gap between items */
  }

  .news-item-container {
    padding: 1rem; /* Reduce padding */
    margin-top: 0; /* Remove margin-top to reduce spacing between containers */
  }

  .news-title-container {
    font-size: 1.1rem; /* Slightly reduce font size */
  }

  .news-button-container {
    font-size: 0.9rem; /* Adjust button font size */
  }
}
/* Адаптация для 1024px и ниже */
@media (min-width: 1720px) {


    .contact-header .header-image {
        width: 100%;
  height: 60vh;
  max-height: 60vh;
  object-position: 50% 43%; /* 50% по горизонтали, 60% по вертикали, ниже центра */
  display: block;
    }
}
@media (max-width: 768px) {
  .news-header-container h1 {
    font-size: 1.8rem; /* Reduce font size */
    top: 13vh; /* Adjust positioning */
    left: 15%; /* Center horizontally */
    transform: translateX(-50%);
  }

  .news-content-container {
    max-width: 600px; /* Further reduce max-width */
    padding: 0; /* Remove padding */
    gap: 1rem; /* Reduce gap between items */
  }

  .news-item-container {
    padding: 1rem; /* Reduce padding */
    margin-top: 0; /* Remove margin-top to reduce spacing between containers */
  }

  .news-title-container {
    font-size: 1.1rem; /* Slightly reduce font size */
  }

  .news-button-container {
    font-size: 0.9rem; /* Adjust button font size */
  }
}

@media (max-width: 480px) {
  .news-header-container h1 {
    font-size: 1.2rem; /* Smaller font size */
    top: 8vh; /* Adjust positioning */
    left: 15%; /* Center horizontally */
    transform: translateX(-50%);
  }

  .news-content-container {
    max-width: 100%; /* Full width */
    padding: 0 0.5rem; /* Reduce padding */
    gap: 3rem; /* Reduce gap between items */
  }

  .news-item-container {
    padding: 0.8rem; /* Reduce padding */
    min-height: 180px; /* Reduce min-height */
    margin-top: -2rem;
  }

  .news-title-container {
    font-size: 1rem; /* Smaller font size */
  }

  .news-button-container {
    font-size: 0.8rem; /* Adjust button font size */
    padding: 0.4rem 0.8rem; /* Adjust padding */
  }

  .page-number,
  .page-arrow {
    margin: 0 0.5rem; /* Adjust margin */
    padding: 0.5rem 1rem; /* Adjust padding */
    font-size: 0.9rem; /* Adjust font size */
  }
}
@media (max-width: 393px) {
  .news-header-container h1 {
    font-size: 0.9rem; /* Smaller font size */
    top: 10vh; /* Adjust positioning */
    left: 15%; /* Center horizontally */
    transform: translateX(-50%);
  }

  .news-content-container {
    max-width: 100%; /* Full width */
    padding: 0 0.5rem; /* Reduce padding */
    gap: 3rem; /* Reduce gap between items */
  }

  .news-item-container {
    padding: 0.8rem; /* Reduce padding */
    min-height: 180px; /* Reduce min-height */
    margin-top: -2rem;
  }

  .news-title-container {
    font-size: 1rem; /* Smaller font size */
  }

  .news-button-container {
    font-size: 0.8rem; /* Adjust button font size */
    padding: 0.4rem 0.8rem; /* Adjust padding */
  }

  .page-number,
  .page-arrow {
    margin: 0 0.5rem; /* Adjust margin */
    padding: 0.5rem 1rem; /* Adjust padding */
    font-size: 0.9rem; /* Adjust font size */
  }
}
@media (max-width: 375px) {
  .news-header-container h1 {
    font-size: 0.9rem; /* Smaller font size */
    top: 12vh; /* Adjust positioning */
    left: 15%; /* Center horizontally */
    transform: translateX(-50%);
  }

  .news-content-container {
    max-width: 100%; /* Full width */
    padding: 0 0.5rem; /* Reduce padding */
    gap: 3rem; /* Reduce gap between items */
  }

  .news-item-container {
    padding: 0.8rem; /* Reduce padding */
    min-height: 180px; /* Reduce min-height */
    margin-top: -2rem;
  }

  .news-title-container {
    font-size: 1rem; /* Smaller font size */
  }

  .news-button-container {
    font-size: 0.8rem; /* Adjust button font size */
    padding: 0.4rem 0.8rem; /* Adjust padding */
  }

  .page-number,
  .page-arrow {
    margin: 0 0.5rem; /* Adjust margin */
    padding: 0.5rem 1rem; /* Adjust padding */
    font-size: 0.9rem; /* Adjust font size */
  }
}
.category-filter {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem; /* Отступы между категориями */
  margin: 2rem 0; /* Отступ сверху и снизу */
}

.category-option {
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  color: #333;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border: 1px solid #007bff;
  border-radius: 20px;
  transition: background-color 0.3s ease, color 0.3s ease;
  display: flex;
  align-items: center;
}

.category-option input[type="radio"] {
  margin-right: 0.5rem;
  accent-color: #007bff;
}

.category-option:hover {
  background-color: #007bff;
  color: #fff;
}

.category-option input[type="radio"]:checked + span {
  font-weight: 600;
}

/* Адаптация для 1024px и ниже */
@media (min-width: 1720px) {


    .contact-header .header-image {
        width: 100%;
  height: 60vh;
  max-height: 60vh;
  object-position: 50% 43%; /* 50% по горизонтали, 60% по вертикали, ниже центра */
  display: block;
    }
}
@media (max-width: 768px) {
  .category-option {
    font-size: 0.9rem;
    padding: 0.4rem 0.8rem;
  }
}

@media (max-width: 480px) {
  .category-filter {
    flex-direction: column;
    align-items: center;
  }

  .category-option {
    font-size: 0.8rem;
    padding: 0.3rem 0.6rem;
  }
}

@media (max-width: 375px) {
  .category-option {
    font-size: 0.75rem;
    padding: 0.2rem 0.5rem;
    border-radius: 15px;
  }
}