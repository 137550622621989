/* Основные стили футера */
.App-footer {
  background-color: #717171;
  color: #ffffff;
  padding: 2% 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
/* Стили для ссылок, чтобы они выглядели как текст */
.footer-link {
  color: inherit; /* Наследуем цвет от родительского элемента */
  text-decoration: none; /* Убираем подчеркивание */
  pointer-events: auto; /* Оставляем интерактивность для mailto и tel */
  cursor: default; /* Изменяем курсор на стандартный */
}

.footer-link:hover {
  text-decoration: underline; /* Добавляем подчеркивание при наведении */
  color: inherit; /* Ссылка не будет менять цвет при наведении */
}
/* Лого слева */
.footer-logo {
  position: absolute;
  top: 12%;
  left: 6%;
}

.footer-logo img {
  max-height: 70px;
  width: auto;
}

/* Линки чуть левее центра */
.footer-links-and-contact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20%;
  margin-top: 0;
}

.footer-links-container {
  display: flex;
  gap: 50px;
  margin-bottom: 10px;
}

.footer-links-container a {
  color: #CACACA;
  text-decoration: none;
  font-family: Inter, sans-serif;
  font-size: 1.6vw;
  font-weight: 600;
}

.footer-links-container a:hover {
  text-decoration: underline;
  color: #ffffff;
}

/* Контактная информация */
.footer-contact-info {
  display: flex;
  align-items: flex-start;
  gap: 30px;
  font-family: Inter, sans-serif;
  font-size: 1.5vw;
  font-weight: 600;
  line-height: 2.8vw;
}

.footer-contact-info p {
  margin: 0;
  display: flex;
  align-items: center;
}

.footer-contact-info p i {
  margin-right: 10px;
  font-size: 1rem;
}

/* Снизу "ЦНИПИ СТАРК" правее, кнопка левее */
.footer-bottom {
  display: flex;
  justify-content: space-between;
  padding: 1% 6%;
  background-color: #717171;
  color: #ffffff;
}

.footer-company-left {
  text-align: right;
  margin-right: 15%; /* Сдвиг ЦНИПИ СТАРК правее */
  font-family: Inter, sans-serif;
  font-size: 1.5vw;
  font-weight: 700;
}

.footer-button-right {
  display: flex;
  align-items: center;
  margin-left: 10%; /* Сдвиг кнопки левее */
}

.footer-button-right button {
  padding: 10px 20px;
  border: 1px solid #ffffff;
  background-color: transparent;
  color: white;
  font-size: 1.2vw;
  font-family: Inter, sans-serif;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.footer-button-right button:hover {
  background-color: white;
  color: #717171;
}

/* Media Queries */

/* Основные стили футера */
.App-footer {
  background-color: #717171;
  color: #ffffff;
  padding: 2% 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

/* Лого слева */
.footer-logo {
  position: absolute;
  top: 10%;
  left: 6%;
}

.footer-logo img {
  max-height: 70px;
  width: auto;
}

/* Линки и контакты */
.footer-links-and-contact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20%;
  margin-top: 0;
}

.footer-links-container {
  display: flex;
  gap: 50px;
  margin-bottom: 10px;
}

.footer-links-container a {
  color: #CACACA;
  text-decoration: none;
  font-family: Inter, sans-serif;
  font-size: 1.6vw;
  font-weight: 600;
}

.footer-links-container a:hover {
  text-decoration: underline;
  color: #ffffff;
}

/* Контактная информация */
.footer-contact-info {
  display: flex;
  align-items: flex-start;
  gap: 30px;
  font-family: Inter, sans-serif;
  font-size: 1.5vw;
  font-weight: 600;
  line-height: 2.8vw;
}

.footer-contact-info p {
  margin: 0;
  display: flex;
  align-items: center;
}

.footer-contact-info p i {
  margin-right: 10px;
  font-size: 1rem;
}

/* Снизу "ЦНИПИ СТАРК" слева и кнопка справа */
.footer-bottom {
  display: flex;
  justify-content: space-between;
  padding: 1% 2%;
  background-color: #717171;
  color: #ffffff;
}

.footer-company-left {
  text-align: left;
  font-family: Inter, sans-serif;
  font-size: 1.5vw;
  font-weight: 700;
}

.footer-button-right {
  display: flex;
  align-items: center;
}

.footer-button-right button {
  padding: 10px 20px;
  border: 1px solid #ffffff;
  background-color: transparent;
  color: white;
  font-size: 1.2vw;
  font-family: Inter, sans-serif;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.footer-button-right button:hover {
  background-color: white;
  color: #717171;
}

/* 1024px и ниже */
@media (max-width: 1024px) {
  .header-image {
    height: 25vh !important; /* Применяем фиксированную высоту с !important */
    max-height: 25vh !important; /* Ограничиваем максимальную высоту */
    object-fit: cover; /* Изображение обрезается по контейнеру */
    width: 100%; /* На всю ширину */
  }
  .footer-links-container {
    flex-direction: row;
    gap: 20px;
    margin-bottom: 0px;
    padding-left: 97px;
  }
  .footer-links-container a {
    font-size: 1.3rem; /* Увеличиваем размер текста */
  }
  .footer-contact-info {
    flex-direction: column;
    gap: 15px;
    font-size: 1.1rem;
    text-align: center;
    padding-left: 97px;
  }

  .footer-company-left {
    font-size: 1.1rem;
  }

  .footer-button-right {
    text-align: center;
  }

  .footer-button-right button {
    font-size: 1rem;
  }

  .footer-logo img {
    max-height: 55px;
  }
  .footer-logo {
    position: absolute;
    top: 50%;
    left: 10%; /* Сдвигаем логотип чуть правее */
  }
}

/* 820px и ниже */
@media (max-width: 820px) {
  .footer-links-container {
    flex-direction: row;
    gap: 20px;
    margin-top: 50px; /* Сдвигаем линки вниз */
    margin-bottom: 0px;
    padding-left: 97px;
  }
  .footer-links-container a {
    font-size: 1.3rem; /* Увеличиваем размер текста */
  }
  .footer-contact-info {
    flex-direction: column;
    gap: 15px;
    font-size: 1.1rem;
    text-align: center;
    padding-left: 97px;
  }

  .footer-company-left {
    font-size: 1.1rem;
  }

  .footer-button-right {
    text-align: center;
  }

  .footer-button-right button {
    font-size: 1rem;
  }

  .footer-logo img {
    max-height: 55px;
  }
  .footer-logo {
    position: absolute;
    top: 55%;
    left: 10%; /* Сдвигаем логотип чуть правее */
  }
}

/* 768px и ниже */
/* Адаптация для 1024px и ниже */
@media (min-width: 1720px) {


    .contact-header .header-image {
        width: 100%;
  height: 60vh;
  max-height: 60vh;
  object-position: 50% 43%; /* 50% по горизонтали, 60% по вертикали, ниже центра */
  display: block;
    }
}
@media (max-width: 768px) {
  .footer-links-container {
    flex-direction: row;
    gap: 20px;
    margin-top: 50px; /* Сдвигаем линки вниз */
    margin-bottom: 0px;
    padding-left: 97px;
  }
  .footer-links-container a {
    font-size: 1.3rem; /* Увеличиваем размер текста */
  }
  .footer-contact-info {
    flex-direction: column;
    gap: 15px;
    font-size: 1.1rem;
    text-align: center;
    padding-left: 97px;
  }

  .footer-company-left {
    font-size: 1.1rem;
  }

  .footer-button-right {
    text-align: center;
  }

  .footer-button-right button {
    font-size: 1rem;
  }

  .footer-logo img {
    max-height: 55px;
  }
  .footer-logo {
    position: absolute;
    top: 55%;
    left: 10%; /* Сдвигаем логотип чуть правее */
  }
}

/* 430px и ниже */
@media (max-width: 430px) {
  .footer-content {
    padding: 2rem 1rem;
  }

  .footer-links-container {
    flex-direction: row;
    justify-content: flex-end; /* Сдвигаем линки вправо */
    gap: 40px; /* Увеличиваем расстояние между линками */
    margin-top: 50px; /* Сдвигаем линки вниз */
    margin-bottom: 0px;
    padding-left: 17px; /* Добавляем отступ справа для более красивого выравнивания */
  }

  .footer-links-container a {
    font-size: 0.8rem; /* Увеличиваем размер текста */
  }

  .footer-contact-info {
    font-size: 0.6rem; /* Увеличиваем текст для удобства чтения */
    text-align: center;
    gap: 5px;
    padding-left: 30px; /* Добавляем отступ справа для более красивого выравнивания */
  }

  .footer-company-left {
    font-size: 0.9rem;
    text-align: center;
  }

  .footer-button-right {
    text-align: center;
  }

  .footer-button-right button {
    font-size: 1rem; /* Сделаем кнопку немного больше */
  }

  .footer-logo img {
    max-height: 35px;
  }

  .footer-logo {
    position: absolute;
    top: 8%;
    left: 36%; /* Сдвигаем логотип чуть правее */
  }
}

/* 393px и ниже */
@media (max-width: 393px) {
  .footer-content {
    padding: 2rem 1rem;
  }

  .footer-links-container {
    flex-direction: row;
    justify-content: flex-end; /* Сдвигаем линки вправо */
    gap: 40px; /* Увеличиваем расстояние между линками */
    margin-top: 50px; /* Сдвигаем линки вниз */
    margin-bottom: 0px;
    padding-left: 17px; /* Добавляем отступ справа для более красивого выравнивания */
  }

  .footer-links-container a {
    font-size: 0.8rem; /* Увеличиваем размер текста */
  }

  .footer-contact-info {
    font-size: 0.6rem; /* Увеличиваем текст для удобства чтения */
    text-align: center;
    gap: 5px;
    padding-left: 30px; /* Добавляем отступ справа для более красивого выравнивания */
  }

  .footer-company-left {
    font-size: 0.9rem;
    text-align: center;
  }

  .footer-button-right {
    text-align: center;
  }

  .footer-button-right button {
    font-size: 1rem; /* Сделаем кнопку немного больше */
  }

  .footer-logo img {
    max-height: 35px;
  }

  .footer-logo {
    position: absolute;
    top: 7%;
    left: 38%; /* Сдвигаем логотип чуть правее */
  }
}