.vacancy-page {
  padding-top: 0;
}

.vacancy-header {
  text-align: center;
  margin-bottom: 0px;
}

.vacancy-page-header-container h1 {
  position: absolute;
  top: 23%;
  left: 17%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 40px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  line-height: 28px;
  z-index: 2;
}

.vacancy-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.vacancy-item {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  background-color: #f9f9f9;
  margin-top: 50px;
  width: 70%;
  max-width: 1000px;
  margin-left: 140px;
  margin-bottom: 70px;
}

.vacancy-link {
  color: #007bff;
  text-decoration: none;
  margin-top: 10px;
  display: inline-block;
}

.vacancy-link:hover {
  text-decoration: underline;
}

.vacancy-link:visited {
  color: #007bff;
  text-decoration: none;
}

/* VacancyDetailPage styles */
.vacancy-detail-page {
  padding: 20px;
}

.vacancy-detail-header {
  text-align: center;
  margin-bottom: 40px;
}

.vacancy-detail-content {
  max-width: 800px;
  margin: 0 auto;
}

.vacancy-detail-content section {
  margin-bottom: 20px;
}

.contact-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.contact-button:hover {
  background-color: #0056b3;
}

/* Adaptation for 1024px and below */
@media (max-width: 1024px) {
  .header-image {
    height: 25vh !important; /* Применяем фиксированную высоту с !important */
    max-height: 25vh !important; /* Ограничиваем максимальную высоту */
    object-fit: cover; /* Изображение обрезается по контейнеру */
    width: 100%; /* На всю ширину */
  }
  .vacancy-page-header-container h1 {
      font-size: 2.5rem;
  }
  .vacancy-item {
    width: 90%; 
    margin-left: 5%; 
    margin-top: 100px;
    min-height: 570px; /* Увеличиваем минимальную высоту */
  }

  .vacancy-detail-content {
    width: 90%;
    margin-left: 5%;
    margin-top: 20px;
    min-height: 550px; /* Увеличиваем минимальную высоту */
  }
  .vacancy-link, .contact-button {
      font-size: 1.1rem;
  }
}

/* Adaptation for 820px and below */
@media (max-width: 820px) {
  .vacancy-page-header-container h1 {
      font-size: 2rem;
  }
  .vacancy-item {
    width: 90%; 
    margin-left: 5%; 
    margin-top: 20px;
    min-height: 500px; /* Увеличиваем минимальную высоту */
  }

  .vacancy-detail-content {
    width: 90%;
    margin-left: 5%;
    margin-top: 20px;
    min-height: 500px; /* Увеличиваем минимальную высоту */
  }
  .vacancy-link, .contact-button {
      font-size: 1rem;
  }
}

/* Adaptation for 768px and below */
/* Адаптация для 1024px и ниже */
@media (min-width: 1720px) {


    .contact-header .header-image {
        width: 100%;
  height: 60vh;
  max-height: 60vh;
  object-position: 50% 43%; /* 50% по горизонтали, 60% по вертикали, ниже центра */
  display: block;
    }
}
@media (max-width: 768px) {
  .vacancy-page {
    padding: 0;
}
  .vacancy-page-header-container h1 {
      font-size: 1.8rem;
  }
  .vacancy-item {
    width: 90%; 
    margin-left: 5%; 
    margin-top: 20px;
    min-height: 390px; /* Увеличиваем минимальную высоту */
  }

  .vacancy-detail-content {
    width: 90%;
    margin-left: 5%;
    margin-top: 20px;
    min-height: 350px; /* Увеличиваем минимальную высоту */
  }
  .vacancy-link, .contact-button {
      font-size: 0.9rem;
  }
}

/* Adaptation for 430px and below */
@media (max-width: 430px) {
  .vacancy-page-header-container h1 {
      font-size: 1.5rem;
  }
  .vacancy-item {
    width: 90%; 
    margin-left: 5%; 
    margin-top: 20px;
    min-height: 500px; /* Увеличиваем минимальную высоту */
  }

  .vacancy-detail-content {
    width: 90%;
    margin-left: 5%;
    margin-top: 20px;
    min-height: 500px; /* Увеличиваем минимальную высоту */
  }
  .vacancy-link, .contact-button {
      font-size: 0.8rem;
  }
}

/* Adaptation for 393px and below */
@media (max-width: 393px) {
  .vacancy-page-header-container h1 {
    font-size: 0.9rem; /* Smaller font size */
    top: 10vh; /* Adjust positioning */
    left: 15%; /* Center horizontally */
    transform: translateX(-50%);
  }
  .vacancy-item {
    width: 90%; 
    margin-left: 5%; 
    margin-top: 20px;
    min-height: 500px; /* Увеличиваем минимальную высоту */
  }

  .vacancy-detail-content {
    width: 90%;
    margin-left: 5%;
    margin-top: 20px;
    min-height: 500px; /* Увеличиваем минимальную высоту */
  }
  .vacancy-link, .contact-button {
      font-size: 0.75rem;
  }

  .vacancy-page-header-container {
      margin-bottom: 10px; /* Уменьшаем нижний отступ */
  }

  footer {
      margin-bottom: 0; /* Убираем лишний нижний отступ у футера */
  }
}
/* Adaptation for 375px and below */
@media (max-width: 375px) {
  .vacancy-page-header-container h1 {
    font-size: 0.9rem; /* Smaller font size */
    top: 11vh; /* Adjust positioning */
    left: 15%; /* Center horizontally */
    transform: translateX(-50%);
  }
  .vacancy-item, .vacancy-detail-content {
      width: 90%;
      margin-left: 5%;
      margin-top: 30px;
  }
  .vacancy-link, .contact-button {
      font-size: 0.7rem;
  }
}