.breadcrumb {
  margin-top: 0rem; /* Отступ сверху от предыдущего элемента */
  margin-bottom: 0rem; /* Отступ снизу */
  display: flex;
  align-items: center;
}

.breadcrumb-link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.breadcrumb-icon {
  font-size: 1.2rem; /* Используем rem для масштабирования */
  color: #007bff;
  margin-right: 0.5rem;
}

.breadcrumb-text {
  color: #007bff;
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  font-weight: 500;
}

.breadcrumb-text:hover {
  text-decoration: underline;
}
.info-page {
  padding-top: 0;
}

.info-header {
  text-align: center;
  position: relative;
}

.header-image {
  width: 100%;
  height: auto;
  max-height: 55vh;
  object-fit: cover;  /* Сохраняем пропорции */
  object-position: center 10%;  /* Поднимаем изображение выше центра */
  margin-bottom: 4%;
}

.info-header h1 {
  position: absolute;
  top: 60%; /* Опускаем заголовок ниже */
  left: 15%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 3vw;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  line-height: 1.2;
  z-index: 2;
  text-align: center;
}

.info-content {
  max-width: 90%; /* Ограничиваем максимальную ширину */
  width: 100%; /* Устанавливаем ширину в процентах */
  margin: 0 auto;
  padding: 2%; /* Используем проценты для внутреннего отступа */
  margin-top: 3%; /* Используем проценты для внешнего отступа */
}

.paragraph-large {
  font-family: 'Inter', semi-bold;
  font-weight: 600;
  font-size: 1.5rem; /* Используем rem для относительного размера текста */
  line-height: 1.5;
  color: #000000;
  margin-bottom: 2%;
}

.paragraph-normal {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.6;
  color: #000000;
  margin-bottom: 2%;
}

.paragraph-small {
  font-family: 'Inter', Medium;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.6;
  color: #000000;
  margin-bottom: 2%;
}

.styled-list {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.6;
  color: #000000;
  padding-left: 5%; /* Используем проценты для внутреннего отступа */
  margin-bottom: 4%;
}

.styled-list li {
  margin-bottom: 1%;
}

.info-carousel {
  position: relative;
  background-color: #ffffff;
  padding: 2%;
  border-radius: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-top: 3%; /* Отступ сверху */
  display: flex;
  align-items: center; /* Центрируем стрелки вертикально */
}

.carousel-arrow {
  cursor: pointer;
  font-size: 2rem;
  user-select: none;
  color: #000;
  background-color: transparent;
  border-radius: 50%;
  padding: 2%; /* Используем проценты для внутреннего отступа */
  width: 2rem; /* Ширина стрелки */
  height: 2rem; /* Высота стрелки */
}

.carousel-container {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  gap: 2%;
  padding: 2% 0;
  flex-grow: 1; /* Даем контейнеру занимать оставшееся пространство */
}

.carousel-item {
  flex: 0 0 auto;
  background-color: #d9d9d9;
  scroll-snap-align: center;
  border-radius: 1rem;
  padding: 2%;
  width: 20%; /* Ширина в процентах */
  text-align: center;
}

.carousel-photo {
  width: 100%;
  height: 200px;
  background-color: #ffffff;
  margin-bottom: 1%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  font-weight: bold;
}

.carousel-text p {
  margin: 5px 0;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 1rem;
}

.project-text {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.6;
  color: #000000;
  margin-bottom: 5%;
  max-width: 80%; /* Устанавливаем ширину в процентах */
}

/* Медиазапросы для адаптации под мобильные и планшеты */
@media (max-width: 1024px) {
  .header-image {
    height: 25vh !important; /* Применяем фиксированную высоту с !important */
    max-height: 25vh !important; /* Ограничиваем максимальную высоту */
    object-fit: cover; /* Изображение обрезается по контейнеру */
    width: 100%; /* На всю ширину */
  }
  .info-header h1 {
    font-size: 2.5rem; /* Reduce font size */
    top: 60%; /* Adjust positioning */
    left: 15%; /* Center horizontally */
    transform: translateX(-50%);
  }

  .breadcrumb {
    margin-top: 1rem; /* Отступ сверху для хлебных крошек */
    margin-bottom: 1rem; /* Отступ снизу */
  }

  .carousel-arrow.left {
    left: 2%;
  }

  .carousel-arrow.right {
    right: 2%;
  }
}

/* Адаптация для 1024px и ниже */
@media (min-width: 1720px) {


    .contact-header .header-image {
        width: 100%;
  height: 60vh;
  max-height: 60vh;
  object-position: 50% 43%; /* 50% по горизонтали, 60% по вертикали, ниже центра */
  display: block;
    }
}
@media (max-width: 768px) {
  .header-image {
    max-height: 65vh; /* Увеличиваем высоту блока с изображением */
  }

  .info-header h1 {
    font-size: 1.8rem; /* Reduce font size */
    top: 13vh; /* Adjust positioning */
    left: 15%; /* Center horizontally */
    transform: translateX(-50%);
  }

  .breadcrumb {
    margin-top: 1rem; /* Отступ сверху для хлебных крошек */
    margin-bottom: 1rem; /* Отступ снизу */
    text-align: left; /* Выровняем хлебные крошки слева */
  }

  .carousel-item {
    width: 40%; /* Увеличиваем размер карусели на мобильных устройствах */
  }

  .paragraph-large {
    font-size: 1rem; /* Еще больше уменьшаем текст */
    line-height: 1.3;
  }

  .paragraph-normal {
    font-size: 0.9rem; /* Уменьшаем размер текста */
    line-height: 1.4;
  }

  .paragraph-small {
    font-size: 1rem; /* Еще больше уменьшаем текст */
    line-height: 1.3;
  }

  .styled-list {
    font-size: 0.9rem; /* Уменьшенный размер */
  }

  .project-text {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .header-image {
    max-height: 70vh; /* Увеличиваем высоту блока с изображением */
  }

  .info-header h1 {
    font-size: 1.2rem; /* Smaller font size */
    top: 8vh; /* Adjust positioning */
    left: 15%; /* Center horizontally */
    transform: translateX(-50%);
  }

  .breadcrumb {
    margin-top: 0.5rem; /* Отступ сверху для хлебных крошек */
    margin-bottom: 1rem; /* Отступ снизу */
    text-align: left; /* Выровняем хлебные крошки слева */
  }

  .info-content {
    width: 100%; /* Увеличиваем ширину для мобильных устройств */
  }

  .carousel-arrow {
    display: none; /* Скрываем стрелки на маленьких экранах */
  }

  .carousel-item {
    width: 60%; /* Еще больше увеличиваем размер карусели для маленьких экранов */
  }

  .paragraph-large {
    font-size: 0.9rem; /* Еще меньше для маленьких экранов */
    line-height: 1.2;
  }

  .paragraph-normal {
    font-size: 0.8rem; /* Уменьшенный размер */
    line-height: 1.2;
  }

  .paragraph-small {
    font-size: 0.9rem; /* Уменьшенный размер */
    line-height: 1.2;
  }

  .styled-list {
    font-size: 0.8rem; /* Уменьшенный размер */
  }

  .project-text {
    font-size: 0.8rem;
  }
}

@media (max-width: 393px) {
  .header-image {
    max-height: 75vh; /* Увеличиваем высоту блока с изображением */
  }

  .info-header h1 {
    font-size: 0.9rem; /* Smaller font size */
    top: 10vh; /* Adjust positioning */
    left: 15%; /* Center horizontally */
    transform: translateX(-50%);
  }

  .breadcrumb {
    margin-top: 0.5rem; /* Отступ сверху для хлебных крошек */
    margin-bottom: 1rem; /* Отступ снизу */
    text-align: left; /* Выровняем хлебные крошки слева */
  }

  .info-content {
    width: 100%; /* Увеличиваем ширину для мобильных устройств */
  }

  .carousel-arrow {
    display: none; /* Скрываем стрелки на маленьких экранах */
  }

  .carousel-item {
    width: 60%; /* Еще больше увеличиваем размер карусели для маленьких экранов */
  }

  .project-text {
    font-size: 1rem;
  }
}
@media (max-width: 375px) {
  .header-image {
    max-height: 75vh; /* Увеличиваем высоту блока с изображением */
  }

  .info-header h1 {
    font-size: 0.9rem; /* Smaller font size */
    top: 12vh; /* Adjust positioning */
    left: 15%; /* Center horizontally */
    transform: translateX(-50%);
  }

  .breadcrumb {
    margin-top: 0.5rem; /* Отступ сверху для хлебных крошек */
    margin-bottom: 1rem; /* Отступ снизу */
    text-align: left; /* Выровняем хлебные крошки слева */
  }

  .info-content {
    width: 100%; /* Увеличиваем ширину для мобильных устройств */
  }

  .carousel-arrow {
    display: none; /* Скрываем стрелки на маленьких экранах */
  }

  .carousel-item {
    width: 60%; /* Еще больше увеличиваем размер карусели для маленьких экранов */
  }

  .project-text {
    font-size: 1rem;
  }
}