/* Styles for all screen sizes */
.partners-page {
  padding-top: 0;
}

.partner-page-header-container {
  text-align: center;
  margin-bottom: 30px;
  position: relative;
}

.partner-page-header-container h1 {
  position: absolute;
    top: 50%; /* Опускаем заголовок ниже */
    left: 15%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 2.5vw; /* Используем vw для адаптивного размера шрифта */
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    line-height: 1.2;
    z-index: 2;
}

.partners-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px 30px; /* Уменьшили начальные значения для более контролируемого расстояния */
  width: 70%;
  margin: 0 auto 100px; /* Центрируем контейнер */
  min-height: calc(60vh - 400px);
}

.partner-item {
  background-color: #e4e4e4;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.271);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 200px;
  width: 100%;
}

.partner-logo {
  max-width: 100px;
  margin-bottom: 15px;
}

.partner-name {
  font-family: 'Inter', sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  /* Задаем фиксированную высоту и перенос текста, чтобы заголовки были на одном уровне */
  height: 50px; /* Установите подходящую высоту для ваших заголовков */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.partner-description {
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  color: #555;
  margin-bottom: 15px;
  /* Задаем фиксированную высоту для описания */
  height: 60px; /* Установите подходящую высоту для вашего описания */
  overflow: hidden;
  text-overflow: ellipsis;
}

.partner-link {
  color: #007bff;
  font-weight: bold;
  text-decoration: none;
  margin-top: auto;
}

.partner-link:hover {
  text-decoration: underline;
}

.pagination {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.pagination span {
  margin: 0 5px;
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  color: #007bff;
  cursor: pointer;
}

.pagination .active {
  font-weight: bold;
  text-decoration: underline;
}

/* Updated Breadcrumb Styles for Partners */
.breadcrumb-partners {
  display: flex;
  align-items: center;
  padding-left: 2rem; /* Добавляем отступ слева */
  margin-top: 0rem; /* Отступ сверху от предыдущего элемента */
  margin-bottom: 0rem; /* Отступ снизу */
}

.breadcrumb-partners-link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.breadcrumb-partners-icon {
  font-size: 1rem;
  color: #007bff;
  margin-right: 5px;
}

.breadcrumb-partners-text {
  color: #007bff;
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  font-weight: 500;
}

.breadcrumb-partners-text:hover {
  text-decoration: underline;
}

/* Media queries for responsive design */

/* Tablet screens */
/* Tablet screens */
@media (max-width: 1280px) {
  .header-image {
    height: 50vh !important; /* Применяем фиксированную высоту с !important */
    max-height: 50vh !important; /* Ограничиваем максимальную высоту */
    object-fit: cover; /* Изображение обрезается по контейнеру */
    width: 100%; /* На всю ширину */
}
  .partners-list {
    grid-template-columns: 1fr; /* Одна колонка */
    gap: 30px 20px;
    width: 80%;
    margin: 0 auto;
  }

  .partner-page-header-container h1 {
    font-size: 2.5rem; /* Reduce font size */
    top: 50%; /* Adjust positioning */
    left: 15%; /* Center horizontally */
    transform: translateX(-50%);
  }
}

@media (max-width: 1024px) {
  .header-image {
    height: 25vh !important; /* Применяем фиксированную высоту с !important */
    max-height: 25vh !important; /* Ограничиваем максимальную высоту */
    object-fit: cover; /* Изображение обрезается по контейнеру */
    width: 100%; /* На всю ширину */
  }
  .partners-list {
    grid-template-columns: 1fr; /* Одна колонка */
    gap: 30px 20px;
    width: 80%;
    margin: 0 auto;
  }

  .partner-page-header-container h1 {
    font-size: 2rem; /* Reduce font size */
    top: 50%;
    left: 19%; /* Center horizontally */
    transform: translateX(-50%);
  }
}

@media (max-width: 820px) {
  .partner-page-header-container h1 {
    font-size: 1.6rem; /* Reduce font size */
    top: 50%; /* Adjust positioning */
    left: 19%; /* Center horizontally */
    transform: translateX(-50%);
  }
}

/* Small tablets and large phones */
/* Адаптация для 1024px и ниже */
@media (min-width: 1720px) {


    .contact-header .header-image {
        width: 100%;
  height: 60vh;
  max-height: 60vh;
  object-position: 50% 43%; /* 50% по горизонтали, 60% по вертикали, ниже центра */
  display: block;
    }
}
@media (max-width: 768px) {
  .partners-list {
    grid-template-columns: 1fr; /* Одна колонка */
    gap: 20px 15px;
    width: 90%;
    margin: 0 auto;
  }

  .partner-page-header-container h1 {
    font-size: 1.5rem; /* Reduce font size */
    top: 13vh; /* Adjust positioning */
    left: 19%; /* Center horizontally */
    transform: translateX(-50%);
  }
}

/* Small phones */
@media (max-width: 480px) {
  .partners-list {
    grid-template-columns: 1fr; /* Одна колонка */
    gap: 20px 10px;
    width: 90%;
    margin: 0 auto;
  }

  .partner-page-header-container h1 {
    font-size: 0.8rem; /* Smaller font size */
    top: 9vh; /* Adjust positioning */
    left: 18%; /* Center horizontally */
    transform: translateX(-50%);
  }

  .partner-item {
    padding: 15px;
  }

  .partner-logo {
    max-width: 80px; /* Adjust logo size */
  }

  .partner-name {
    font-size: 1.2rem; /* Adjust font size */
  }

  .partner-description {
    font-size: 0.9rem; /* Adjust font size */
  }

  .breadcrumb-partners {
    top: 10px; /* Adjust position */
  }
}

/* For iPhone 15 Pro (393px width) */
@media (max-width: 393px) {
  .partners-list {
    grid-template-columns: 1fr; /* Одна колонка */
    gap: 15px 10px;
    width: 90%;
    margin: 0 auto;
  }

  .partner-page-header-container h1 {
    font-size: 0.7rem; /* Smaller font size */
    top: 10vh; /* Adjust positioning */
    left: 18%; /* Center horizontally */
    transform: translateX(-50%);
  }

  .partner-item {
    padding: 10px; /* Further reduce padding */
  }

  .partner-logo {
    max-width: 70px; /* Adjust logo size */
  }

  .partner-name {
    font-size: 1.1rem; /* Further adjust font size */
  }

  .partner-description {
    font-size: 0.8rem; /* Further adjust font size */
  }

  .breadcrumb-partners {
    top: 5px; /* Adjust position */
  }
}

@media (max-width: 375px) {
  .partners-list {
    grid-template-columns: 1fr; /* Одна колонка */
    gap: 15px 10px;
    width: 90%;
    margin: 0 auto;
  }

  .partner-page-header-container h1 {
    font-size: 0.6rem; /* Smaller font size */
    top: 12vh; /* Adjust positioning */
    left: 17%; /* Center horizontally */
    transform: translateX(-50%);
  }

  .partner-item {
    padding: 10px; /* Further reduce padding */
  }

  .partner-logo {
    max-width: 70px; /* Adjust logo size */
  }

  .partner-name {
    font-size: 1.1rem; /* Further adjust font size */
  }

  .partner-description {
    font-size: 0.8rem; /* Further adjust font size */
  }

  .breadcrumb-partners {
    top: 5px; /* Adjust position */
  }
}