.mission-section {
    padding: 50px 15px;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.mission-title {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
    text-align: left;
    font-family: 'Inter', sans-serif;
    width: 100%;
    max-width: 100%; 
    padding-left: 10%; /* Отступ слева для заголовка */
    position: relative; /* Для добавления псевдоэлементов перед и после */
}

.mission-title::before,
.mission-title::after {
    content: '';
    display: block;
    width: 50%; /* Линия идет и вправо, и влево на 50% ширины */
    height: 1px;
    background-color: #ccc;
    position: absolute;
    top: 100%; /* Линия появляется под заголовком */
}

.mission-title::before {
    left: 0;
}

.mission-title::after {
    right: 0;
}

.mission-description {
    font-size: 1.6rem;
    line-height: 1.4;
    color: #515151;
    text-align: left;
    margin-bottom: 10px; 
    font-family: 'Inter', sans-serif;
    max-width: 60%;
    margin-top: 5%;
    font-weight: 600;
    margin-left: -20%; /* Используем отрицательный отступ для сдвига влево */
}

.mission-description + .mission-description {
    margin-top: 10px;
}

.mission-learn-more-button {
    padding: 10px 40px; /* Увеличиваем отступы внутри кнопки для растяжения текста */
    font-size: 16px;
    background-color: transparent;
    color: #333;
    border: 2px solid #333;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    align-self: flex-end;
    margin-right: 15%; /* Сдвигаем кнопку правее */
    margin-bottom: 5%;
    margin-top: 3%;
    white-space: nowrap; /* Чтобы текст не переносился на новую строку */
}

.mission-learn-more-button:hover {
    background-color: #333;
    color: white;
}

/* Адаптация для 1024px и ниже */
@media (min-width: 1720px) {


    .contact-header .header-image {
        width: 100%;
  height: 60vh;
  max-height: 60vh;
  object-position: 50% 43%; /* 50% по горизонтали, 60% по вертикали, ниже центра */
  display: block;
    }
}
@media (max-width: 768px) {
    .mission-title {
        font-size: 1.6rem;
    }

    .mission-description {
        font-size: 1rem;
        padding-left: 0; /* На мобильных устройствах убираем отступ слева */
    }

    .mission-learn-more-button {
        font-size: 14px;
        margin-right: 0;
    }
}
@media (max-width: 393px) {
    .mission-title {
        font-size: 1.8rem;
        font-weight: bold;
        margin-bottom: 20px;
        color: #333;
        text-align: left;
        font-family: 'Inter', sans-serif;
        width: 100%;
        max-width: 100%; 
        padding-left: 10%; /* Отступ слева для заголовка */
        position: relative; /* Для добавления псевдоэлементов перед и после */
    }
    .mission-description {
        text-align: left;
    }
}