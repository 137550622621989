/* Новости */
.news-section {
  background-color: #f8f9fa;
  padding: 40px 0;
  min-height: 80vh;
  width: 100%;
}

.news-wrapper {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
}

.news-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.news-title {
  font-family: 'Lora', serif;
  font-weight: 500;
  font-size: 2.5rem;
}

.all-news-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #064588;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.all-news-button:hover {
  background-color: #003f72;
}

.all-news-button-container {
  text-align: right;
  margin-top: 20px;
}

/* Список новостей */
.news-list {
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 20px;
}

.news-item {
  background-color: #eaeaea;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: calc(33.33% - 20px); /* Три новости в ряд */
  min-height: 300px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Медиазапросы для новостей */
@media (max-width: 1200px) {
  .news-item {
      width: calc(50% - 20px); /* Две новости в ряд для экранов меньше 1200px */
  }
}

/* Адаптация для 1024px и ниже */
@media (min-width: 1720px) {


    .contact-header .header-image {
        width: 100%;
  height: 60vh;
  max-height: 60vh;
  object-position: 50% 43%; /* 50% по горизонтали, 60% по вертикали, ниже центра */
  display: block;
    }
}
@media (max-width: 768px) {
  .news-item {
      width: 100%; /* Одна новость в ряд для маленьких экранов */
  }

  .news-title {
      text-align: center;
  }
}

@media (max-width: 480px) {
  .all-news-button {
      width: 100%;
      font-size: 14px;
  }

  .news-item {
      width: 100%; /* На телефонах показываем одну новость */
  }

  .news-title-section {
      font-size: 1.3rem;
  }

  .news-content {
      font-size: 1rem;
  }

  .news-title {
      font-size: 2rem;
  }
}