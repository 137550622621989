/* Styles for all screen sizes */
.news-detail-page-container {
  padding-top: 0;
}
.news-detail-image {
  display: block;
  width: 100%;
  max-width: 800px; /* Максимальная ширина для изображения */
  max-height: none; /* Ограничиваем максимальную высоту изображения */
  height: auto;
  margin: 20px auto; /* Отступы сверху и снизу, центрируем по горизонтали */
  border-radius: 8px;
  object-fit: cover; /* Обрезка изображения по контейнеру, сохраняя пропорции */
}
.news-detail-header-container {
  text-align: center;
  position: relative;
}

.news-detail-header-image {
  width: 100%;
  height: auto;
  max-height: 55vh; /* Match the height from the first code block */
  object-fit: cover;
}

.news-detail-header-container h1 {
  position: absolute;
  top: 60%;
  left: 15%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 3vw; /* Use vw for responsiveness */
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  line-height: 1.2;
  z-index: 2;
}

.news-detail-content-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.news-detail-item-container {
  background-color: #d9d9d9;
  padding: 30px;
  border-radius: 0px;
  max-width: 1120px;
  width: 100%;
  height: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.news-detail-date-container {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}

.news-detail-title-container {
  font-family: 'Inter', sans-serif;
  font-size: 1.5rem; /* Use rem for better scaling */
  color: #007bff;
  margin-bottom: 20px;
  overflow-wrap: break-word;
}

.news-detail-description-container {
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  color: #333;
  line-height: 1.6;
  overflow-wrap: break-word;
}

.news-details-breadcrumb {
  max-width: 1120px;
  padding: 10px;
  margin: 1rem auto;
}

.news-details-breadcrumb-link {
  text-decoration: none;
}

.news-details-breadcrumb-text {
  color: #007bff;
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  font-weight: 500;
}

/* Media queries for responsive design */
@media (max-width: 820px) {
  .news-detail-header-container h1 {
    font-size: 2.5rem; /* Adjust font size */
    top: 50%; /* Adjust positioning */
  }

  .news-detail-content-container {
    max-width: 90%; /* Adjust max-width for the entire content */
    display: flex; /* Enable flexbox */
    justify-content: center; /* Center the child elements */
    align-items: center; /* Center vertically if needed */
  }

  .news-detail-item-container {
    padding: 20px; /* Adjust padding */
    max-width: 800px; /* Reduce the width of the news detail container */
    width: 100%; /* Ensure it takes full width up to max-width */
    margin: 0 auto; /* Center the container */
  }

  .news-details-breadcrumb {
    max-width: 90%; /* Adjust max-width */
    padding: 5px; /* Adjust padding */
  }
}
@media (max-width: 1024px) {
  .header-image {
    height: 25vh !important; /* Применяем фиксированную высоту с !important */
    max-height: 25vh !important; /* Ограничиваем максимальную высоту */
    object-fit: cover; /* Изображение обрезается по контейнеру */
    width: 100%; /* На всю ширину */
  }
  .news-detail-item-container {
    padding: 15px; /* Further reduce padding */
    margin-left: 80px; /* Устанавливаем отступ слева в 60px */
    margin-right: 10px; /* Дополнительно, чтобы было симметрично справа */
  }
}
/* Адаптация для 1024px и ниже */
@media (min-width: 1720px) {


    .contact-header .header-image {
        width: 100%;
  height: 60vh;
  max-height: 60vh;
  object-position: 50% 43%; /* 50% по горизонтали, 60% по вертикали, ниже центра */
  display: block;
    }
}
@media (max-width: 768px) {
  .news-detail-header-container h1 {
    font-size: 2rem; /* Further reduce font size */
    top: 50%;
    left: 50%;
  }

  .news-detail-content-container {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }

  .news-detail-item-container {
    padding: 15px; /* Further reduce padding */
    margin-left: 80px; /* Устанавливаем отступ слева в 60px */
    margin-right: 10px; /* Дополнительно, чтобы было симметрично справа */
  }

  .news-details-breadcrumb {
    max-width: 90%; /* Further reduce the max width */
    padding: 5px; /* Adjust padding */
  }
}

@media (max-width: 480px) {
  .news-detail-header-container h1 {
    font-size: 1.8rem; /* Further reduce font size */
    top: 8vh; /* Adjust positioning */
    left: 15%; /* Adjust positioning */
    transform: translateX(-50%);
  }

  .news-detail-content-container {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }

  .news-detail-item-container {
    padding: 10px; /* Reduce padding for small screens */
    margin-left: 80px; /* Устанавливаем отступ слева в 60px */
    margin-right: 10px; /* Дополнительно справа */
  }

  .news-details-breadcrumb {
    max-width: 90%;
    padding: 5px; /* Adjust padding */
  }

  .news-detail-title-container {
    font-size: 1rem; /* Adjust font size */
  }

  .news-detail-description-container {
    font-size: 0.9rem; /* Adjust font size */
  }
}

@media (max-width: 393px) {
  .news-detail-header-container h1 {
    font-size: 0.9rem; /* Smaller font size */
    top: 10vh; /* Adjust positioning */
    left: 15%; /* Center horizontally */
    transform: translateX(-50%);
  }

  .news-detail-item-container {
    padding: 8px; /* Further reduce padding for very small screens */
    margin-left: 80px; /* Устанавливаем отступ слева в 60px */
    margin-right: 10px; /* Дополнительно справа */
  }

  .news-details-breadcrumb {
    max-width: 90%;
    padding: 5px;
  }

  .news-detail-title-container {
    font-size: 0.9rem; /* Adjust font size */
  }

  .news-detail-description-container {
    font-size: 0.8rem; /* Adjust font size */
  }
}