/* Основные стили */
.partner-detail-page {
    padding-top: 0;
}

.partner-page-header-container {
    text-align: center;
    position: relative;
}

.partner-detail-page-header-container h1 {
    position: absolute;
    top: 43%;
    left: 17%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 2.5vw;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    line-height: 1.2;
    z-index: 2;
}

.partner-detail-content {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    font-family: 'Inter', sans-serif;
    color: #555;
}

.partner-detail-content h2 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 20px;
    color: #333;
}

.partner-detail-content p {
    font-size: 1.125rem;
    line-height: 1.6;
    margin-bottom: 20px;
}

.partner-detail-content ul {
    list-style-type: disc;
    margin-left: 20px;
    padding-left: 20px;
}

.partner-detail-content ul li {
    margin-bottom: 10px;
    font-size: 1.125rem;
}

.partner-detail-content a {
    color: #007bff;
    text-decoration: none;
}

.partner-detail-content a:hover {
    text-decoration: underline;
}

.breadcrumb-partners-b {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    text-align: left;
    padding-left: 2rem; /* Отступ слева */
}

.breadcrumb-partners-link-b {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.breadcrumb-partners-icon-b {
    font-size: 1.2rem;
    color: #007bff;
    margin-right: 0.5rem;
}

.breadcrumb-partners-text-b {
    color: #007bff;
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    font-weight: 500;
}

.breadcrumb-text-b:hover {
    text-decoration: underline;
}

/* Медиазапросы для адаптации под мобильные и планшеты */
@media (max-width: 1024px) {
    .header-image {
        height: 25vh !important; /* Применяем фиксированную высоту с !important */
        max-height: 25vh !important; /* Ограничиваем максимальную высоту */
        object-fit: cover; /* Изображение обрезается по контейнеру */
        width: 100%; /* На всю ширину */
      }
    .partner-detail-page-header-container h1 {
        font-size: 2rem; /* Reduce font size */
    top: 50%; /* Adjust positioning */
    left: 19%; /* Center horizontally */
    transform: translateX(-50%);
    }

    .partner-detail-content {
        max-width: 700px;
        padding: 20px;
    }
}
@media (max-width: 1024px) {
    .partner-detail-page-header-container h1 {
        font-size: 1.6rem; /* Reduce font size */
    top: 50%; /* Adjust positioning */
    left: 19%; /* Center horizontally */
    transform: translateX(-50%);
    }
}
/* Адаптация для 1024px и ниже */
@media (min-width: 1720px) {


    .contact-header .header-image {
        width: 100%;
  height: 60vh;
  max-height: 60vh;
  object-position: 50% 43%; /* 50% по горизонтали, 60% по вертикали, ниже центра */
  display: block;
    }
}
@media (max-width: 768px) {
    .partner-detail-page-header-container h1 {
        font-size: 1.5rem;
        top: 13vh;
        left: 17%;
        transform: translateX(-50%);
    }

    .partner-detail-content {
        max-width: 600px;
        padding: 1rem;
    }

    .partner-detail-content h2 {
        font-size: 1.5rem;
    }

    .partner-detail-content p {
        font-size: 1rem;
    }

    .breadcrumb-partners-b {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}

@media (max-width: 480px) {
    .partner-detail-page-header-container h1 {
        font-size: 1.2rem;
        top: 8vh;
        left: 15%;
        transform: translateX(-50%);
    }

    .partner-detail-content {
        max-width: 100%;
        padding: 0 0.5rem;
    }

    .partner-detail-content h2 {
        font-size: 1.2rem;
    }

    .partner-detail-content p {
        font-size: 0.9rem;
    }

    .breadcrumb-partners-b {
        margin-top: 0.5rem;
        margin-bottom: 1rem;
    }
}

@media (max-width: 393px) {
    .partner-detail-page-header-container h1 {
        font-size: 0.7rem; /* Smaller font size */
        top: 10vh; /* Adjust positioning */
        left: 18%; /* Center horizontally */
        transform: translateX(-50%);
    }

    .partner-detail-content {
        max-width: 100%;
        padding: 0 0.5rem;
    }

    .partner-detail-content h2 {
        font-size: 1rem;
    }

    .partner-detail-content p {
        font-size: 0.8rem;
    }

    .breadcrumb-partners-b {
        margin-top: 0.5rem;
        margin-bottom: 1rem;
    }
}
@media (max-width: 375px) {
    .partner-detail-page-header-container h1 {
        font-size: 0.6rem; /* Smaller font size */
    top: 12vh; /* Adjust positioning */
    left: 17%; /* Center horizontally */
    transform: translateX(-50%);
    }

    .partner-detail-content {
        max-width: 100%;
        padding: 0 0.5rem;
    }

    .partner-detail-content h2 {
        font-size: 1rem;
    }

    .partner-detail-content p {
        font-size: 0.8rem;
    }

    .breadcrumb-partners-b {
        margin-top: 0.5rem;
        margin-bottom: 1rem;
    }
}