.developments-page {
  padding: 0px;
}

.developments-header {
text-align: center;
margin-bottom: 30px;
position: relative;
}

.header-image {
  width: 100%;
  height: auto;
  object-position: 50% 36%; /* 50% по горизонтали, 60% по вертикали, ниже центра */
  display: block;
}

.developments-header h1 {
position: absolute;
top: 50%; /* Опускаем заголовок ниже */
left: 15%;
transform: translate(-50%, -50%);
color: white;
font-size: 3vw;
font-family: 'Inter', sans-serif;
font-weight: 600;
line-height: 1.2;
z-index: 2;
text-align: center;
}

.breadcrumb {
margin-top: 0rem; /* Отступ сверху от предыдущего элемента */
margin-bottom: 0rem; /* Отступ снизу */
display: flex;
align-items: center;
padding-left: 3rem; /* Добавляем отступ слева */
}

.breadcrumb-link {
  text-decoration: none;
  color: #007bff;
  font-size: 18px;
}

.breadcrumb-link:hover {
  text-decoration: underline;
}
.development-image {
  width: 100%; /* Изображение занимает всю ширину карточки */
  height: auto; /* Высота подстраивается автоматически, сохраняя пропорции */
  object-fit: cover; /* Изображение обрезается по размеру контейнера */
}
.developments-list {
  display: flex;
  flex-wrap: wrap; /* Позволяет переносить элементы */
  justify-content: space-between;
  gap: 20px;
  margin-top: 30px;
  padding: 0 40px; /* Добавляем отступы слева и справа */
  margin-bottom: 70px;
}

.development-item {
  width: 30%; /* Уменьшаем ширину карточек для 3-х в ряд */
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  transition: transform 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.development-info {
  padding: 15px;
}

.development-detail-link {
  display: inline-block;
  margin-top: 10px;
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.development-detail-link:hover {
  text-decoration: underline;
}
/* Медиазапросы для 1024px и ниже */
@media (max-width: 1024px) {
  .header-image {
    height: 25vh !important; /* Применяем фиксированную высоту с !important */
    max-height: 25vh !important; /* Ограничиваем максимальную высоту */
    object-fit: cover; /* Изображение обрезается по контейнеру */
    width: 100%; /* На всю ширину */
  }
  .developments-header h1 {
    font-size: 2.5rem; /* Reduce font size */
    top: 50%; /* Adjust positioning */
    left: 18%; /* Center horizontally */
    transform: translateX(-50%);
  }
  .developments-list {
      flex-direction: column;
      align-items: center;
      gap: 40px;
      padding: 0 20px;
    }
  
    .development-item {
      width: 80%; /* Увеличиваем ширину карточек для большего экрана */
    }
  
    .development-image {
      height: 300px; /* Уменьшаем высоту изображений */
    }
  
    .breadcrumb-link {
      font-size: 16px;
    }
  }
/* Медиазапросы для 1024px и ниже */
@media (max-width: 820px) {
  .developments-header h1 {
    font-size: 2rem; /* Reduce font size */
    top: 50%; /* Adjust positioning */
    left: 18%; /* Center horizontally */
    transform: translateX(-50%);
  }
  .developments-list {
      flex-direction: column;
      align-items: center;
      gap: 40px;
      padding: 0 20px;
    }
  
    .development-item {
      width: 80%; /* Увеличиваем ширину карточек для большего экрана */
    }
  
    .development-image {
      height: 300px; /* Уменьшаем высоту изображений */
    }
  
    .breadcrumb-link {
      font-size: 16px;
    }
  }
  
  /* Медиазапросы для 768px и ниже */
  /* Адаптация для 1024px и ниже */
@media (min-width: 1720px) {


  .header-image {
  width: 100%;
  height: 60vh;
  max-height: 60vh;
  object-position: 50% 43%; /* 50% по горизонтали, 60% по вертикали, ниже центра */
  display: block;
    }
}
@media (max-width: 768px) {
    .developments-header h1 {
      font-size: 1.8rem; /* Reduce font size */
    top: 13vh; /* Adjust positioning */
    left: 20%; /* Center horizontally */
    transform: translateX(-50%);
    }
  
    .developments-list {
      padding: 0 10px;
    }
  
    .development-item {
      width: 100%; /* Карточки на всю ширину экрана */
    }
  
    .development-image {
      height: 200px; /* Уменьшаем высоту изображений */
    }
  
    .breadcrumb-link {
      font-size: 14px;
    }
    .breadcrumb{
      padding-left: 1rem; /* Добавляем отступ слева */
    }
  }
  
  /* Медиазапросы для 430px и ниже */
  @media (max-width: 480px) {
    .developments-header h1 {
      font-size: 1.2rem; /* Smaller font size */
    top: 8vh; /* Adjust positioning */
    left: 21%; /* Center horizontally */
    transform: translateX(-50%);
    }
  
    .development-item {
      width: 100%; /* Карточки занимают всю ширину экрана */
    }
  
    .development-image {
      height: 150px; /* Уменьшаем высоту изображений */
    }
  
    .breadcrumb-link {
      font-size: 12px;
    }
    .breadcrumb{
      padding-left: 1rem; /* Добавляем отступ слева */
    }
  }
  
  /* Медиазапросы для 393px и ниже */
  @media (max-width: 393px) {
    .developments-header h1 {
      font-size: 0.9rem; /* Smaller font size */
    top: 10vh; /* Adjust positioning */
    left: 18%; /* Center horizontally */
    transform: translateX(-50%);
    }
  
    .development-item {
      width: 100%;
    }
  
    .development-image {
      height: 120px;
    }
  
    .breadcrumb-link {
      font-size: 11px;
    }
    .breadcrumb{
      padding-left: 1rem; /* Добавляем отступ слева */
    }
  }
  
  /* Медиазапросы для 375px и ниже */
  @media (max-width: 375px) {
    .developments-header h1 {
      font-size: 0.9rem; /* Smaller font size */
    top: 12vh; /* Adjust positioning */
    left: 18%; /* Center horizontally */
    transform: translateX(-50%);
    }
  
    .development-item {
      width: 100%;
    }
  
    .development-image {
      height: 100px;
    }
  
    .breadcrumb-link {
      font-size: 10px;
    }
    .breadcrumb{
      padding-left: 1rem; /* Добавляем отступ слева */
    }
  }